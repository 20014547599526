import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import {ApiService} from "../../../core/api.service";
import {EventActionEnum} from "../../enums/event-action.enum";
import {IQuery} from "../help-panel/help-panel.component";
import {log} from "@capacitor/assets/dist/util/log";
import {ModalService} from "../modal.service";

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
  isVisible: boolean = false;
  private timer: any = null; // Holds the reference to the timeout

  public hover = 0;
  public feedbackSubmitted = false;
  public resource: string = null;
  public feedbackMessage: string = null;
  public ratingForm = new FormGroup({
    rating: new FormControl(0),
    notes: new FormControl(null),
  });

  constructor(
      private _api: ApiService,
      private _modalCtrl: ModalService,
  ) { }

  ngOnInit() {}

  //on view leave
  ngOnDestroy() {
    if (this.ratingAdded && !this.feedbackSubmitted) {
      this.submitFeedback();
    }
  }

  setRating(star: number): void {
    this.ratingForm.patchValue({rating: star});
    if (this.timer) {
      clearTimeout(this.timer); // Clear the timer when a rating is set
      this.timer = null; // Reset the timer variable
    }
  }

  setHover(star: number): void {
    this.hover = star;
  }

  get rating(): number {
    return this.ratingForm.get('rating').value;
  }

  get ratingAdded(): boolean {
    return this.ratingForm.get('rating').value > 0;
  }

  resetRating() {
    this.ratingForm.reset({
      rating: 0,
      notes: null
    });
    this.hover = 0;
  }

  showRating(resource:string, feedbackMessage: string, duration: number = 5000) {
    console.log("showRating" + resource )
    this.feedbackMessage = feedbackMessage;
    this.resetRating();
    this.resource = resource;
    if(localStorage.getItem(this.resource) == 'true'){
      localStorage.removeItem(this.resource)
    }
    else{
      return;
    }

    // fetch existing events for the resource
    this._api.getAllEvents(0,999,null, {resource:'~feedback'}).subscribe((resp) => {
        //skip if there are subitted events for the resource
        const submittedEvents = resp.rows.filter((event) => event.action === EventActionEnum.Submitted);
        const viewedEvents = resp.rows.filter((event) => event.action === EventActionEnum.Viewed);
        if (submittedEvents.length > 0) {
            console.log("You have already rated this resource");
            return;
        }

        // if there have been any events for the resource viewed in last 24hrs skip
        if (viewedEvents.length > 0) {
            const lastEvent = viewedEvents[0];
            const lastEventDate = new Date(lastEvent.timestamp);
            const currentDate = new Date();
            const diff = currentDate.getTime() - lastEventDate.getTime();
            const diffInHours = diff / (1000 * 60 * 60);
            if (diffInHours < 24) {
                console.log("You have already viewed this resource in the last 24 hours");
                return;
            }
        }
      this.createEvent(resource, EventActionEnum.Viewed);
      this.isVisible = true;
      this.timer = setTimeout(() => {
        if (!this.timer) return; // Do nothing if the timer has been cleared
        this.createEvent(resource, EventActionEnum.Ignored)// Create an event for ignoring the rating
        this.isVisible = false;
      }, duration);
    })
  }

  closeRating() {
    this.resetRating();
    this.isVisible = false;
  }

  submitFeedback() {
    this.createEvent(this.resource, EventActionEnum.Submitted, this.ratingForm.value);
    this._modalCtrl.success('Thank you for your feedback!');
    this.feedbackSubmitted = true;
    // Implement your submission logic here
    this.closeRating(); // Close the component after submitting
  }

  createEvent(resource: string, action: EventActionEnum, metadata?: any) {
    const body = { resource, action, metadata };
    this._api.createEvent(body).subscribe((resp) => {});
  }
}
