import { Account } from "src/app/core/user.service";
import { InventoryListing } from "./InventoryRecord";
import { PricingRule } from "./Integration";
import {Warehouse} from "./Warehouse.model";

export class SaleChannel {
  ID: number;
  accountID: number;
  account: Account;
  title: string;
  description: string;
  allowVirtualInventory: boolean
  isDefault: boolean;
  email: string
  password: string
  platform: string
  shopifyStoreName: string
  shopifyAPIAuth: string
  markup: number;
  taxRate: number;
  syncProgress: number;
  policyUrl: string;
  sameDayDeliveryInternalPriorityMargin: number;
  sameDayDelivery : boolean;
  defaultFulfillmentCentreID: number;
  defaultFulfillmentCentre: Warehouse;
  consignmentPricingRule? : PricingRule
  _account_saleChannel: {
    saleChannelID: number
    accountID: number
    fulfillmentPolicy: string
    tier: string
    status: string
  }

  constructor(data: any) {
    if (data == null) return

    data.account = new Account(data?.account)
    data.consignmentPricingRule = new PricingRule(data.consignmentPricingRule)
    data.defaultFulfillmentCentre = this.defaultFulfillmentCentreID ?  new Warehouse(data.defaultFulfillmentCentre): null
    data.markup = parseFloat(data.markup)
    data.taxRate = parseFloat(data.taxRate)

    this._account_saleChannel = data.account_saleChannel || {}
    return Object.assign(this, data)
  }


  getPriceFromPayout(payout: number, productTitle: string, listingAccountID: number, pricingRule: PricingRule = this.consignmentPricingRule): number {
    /**
     * input: payout
     * output: price
     */
    const originalPayout = payout
    const applyPricingRule = pricingRule && listingAccountID != pricingRule.accountID

    let priceAfterConsignmentFees = originalPayout
    //add pricing rule target payout only if exclusive
    if (applyPricingRule && pricingRule.target == "payout") {
      let valueToApply = pricingRule.value
      if (pricingRule.type.includes('range')) {
        const ranges = (pricingRule.value as string).split(',').map(v => {
          const [range, price] = v.split('=')
          const [min, max] = range.split(':')
          return {min: parseFloat(min), max: parseFloat(max), fee: price}
        })
        const sortedRanges = ranges.sort((a, b) => a.min - b.min)
        const range = sortedRanges.find(r => originalPayout < r.max) || sortedRanges[sortedRanges.length - 1]
        valueToApply = range.fee
      }

      valueToApply = parseFloat(valueToApply as string)
      const feeValueComputedByType = {
          "fixed-exclusive": originalPayout + valueToApply,
          "percentage-exclusive": originalPayout / (100 - valueToApply) * 100,
          "fixed-inclusive": originalPayout, //if inclusive is applied only in getPayoutFromPrice
          "percentage-inclusive": originalPayout, //if inclusive is applied only in getPayoutFromPrice
          "range-fixed-exclusive": originalPayout + valueToApply,
          "range-percentage-exclusive": originalPayout / (100 - valueToApply) * 100,
          "range-fixed-inclusive": originalPayout,
          "range-percentage-inclusive": originalPayout,
      }

      const key = `${pricingRule.type}-${pricingRule.apply}`
      priceAfterConsignmentFees = feeValueComputedByType[key]
    }
    const consignmentFeeAmount = priceAfterConsignmentFees - originalPayout

    //add markup
    let priceAfterMarkup = priceAfterConsignmentFees * (100 + this.markup) / 100
    const markupAmount = priceAfterMarkup - priceAfterConsignmentFees

    //add tax rate - the EDIT LDN has 0 tax on kids at harrods
    const isKids = (['ps', 'td', 'kids', 'infants']).find(keyword => (productTitle || '').toLowerCase().includes(keyword));
    const taxRate = (this.title == 'harrods' && isKids) ? 0 : this.taxRate
    let priceAfterTax = priceAfterMarkup * (100 + taxRate) / 100
    const taxAmount = priceAfterTax - priceAfterMarkup

    //add any additional fees - laced charges 6.99 for shipping
    if (this.platform == 'laced') {
        priceAfterTax = priceAfterTax + 6.99 //shipping
        priceAfterTax = (Math.ceil(priceAfterTax / 5) * 5); //round to highest 5
    }

    //console.log('getPriceFromPayout >> ', originalPayout, consignmentFeeAmount, markupAmount, taxAmount, priceAfterTax)

    return Number(priceAfterTax.toFixed(2))
  }

  getPayoutFromPrice(salePrice: number, productTitle: string, listingAccountID: number, pricingRule: PricingRule = this.consignmentPricingRule): number {
    /**
     * input: price
     * output: payout
     */
    const originalSalePrice = salePrice
    salePrice = salePrice

    //subtract any tax rate - the EDIT LDN has 0 tax on kids at harrods
    const isKids = (['ps', 'td', 'kids', 'infants']).find(keyword => (productTitle || '').toLowerCase().includes(keyword));
    const taxRate = (this.title == 'harrods' && isKids) ? 0 : this.taxRate

    //compute tax amount
    const taxAmount = salePrice - (salePrice / (100 + taxRate) * 100)
    salePrice = salePrice - taxAmount

    // remove markup
    let markupAmount = salePrice * (this.markup / (100 + this.markup))

    //laced charges 6.99 for shipping
    if (this.platform == 'laced') {
        markupAmount = markupAmount + 6.99
    }
    salePrice = salePrice - markupAmount

    const applyPricingRule = pricingRule && listingAccountID != pricingRule.accountID
    let consignmentFeeAmount = 0
    //add pricing rule target payout (inclusive & exclusive)
    if (applyPricingRule && pricingRule.target == "payout") {
      let valueToApply = pricingRule.value
      if (pricingRule.type.includes('range')) {
        const ranges = (pricingRule.value as string).split(',').map(v => {
          const [range, price] = v.split('=')
          const [min, max] = range.split(':')
          return {min: parseFloat(min), max: parseFloat(max), fee: price}
        })
        const sortedRanges = ranges.sort((a, b) => a.min - b.min)
        const range = sortedRanges.find(r => salePrice < r.max) || sortedRanges[sortedRanges.length - 1]
        valueToApply = range.fee
      }

      valueToApply = parseFloat(valueToApply as string)
      const feeValueComputedByType = {
          "fixed": valueToApply,
          "percentage": salePrice * valueToApply / 100,
          "range-fixed": valueToApply,
          "range-percentage": salePrice * valueToApply / 100,
      }
      consignmentFeeAmount = feeValueComputedByType[pricingRule.type]
    }
    const payout = salePrice - consignmentFeeAmount

    //console.log('getPayoutFromPrice >> ', originalSalePrice, taxAmount, markupAmount, consignmentFeeAmount, payout)

    return Number(payout.toFixed(2))
  }

  displayConsignmentFee(currency): string {
    if (this.consignmentPricingRule.type == 'fixed') {
        return `${this.consignmentPricingRule.value} ${currency}`
    } else if (this.consignmentPricingRule.type == 'percentage') {
        return `${this.consignmentPricingRule.value} %`
    } else {
        return `Ranges`
    }
  }

  get status(): string | null{
    return this._account_saleChannel.status
  }
}

