import { environment } from "src/environments/environment";
import {Order, OrderLineItem} from "./Order.model";
import { User } from './User.model';
import { Account } from 'src/app/core/user.service';

export class Transaction {
  ID: number;
  stripeID: string;
  type: string;
  status: string;
  paymentMethod: string;
  reference: string;
  currency: string;
  gateway: string;
  paymentCompletedAt: Date;
  revertedAt: Date;
  updatedAt: Date;
  processedByUser: User
  fees: number;
  amount: number;
  net: number;
  toAccountID: number;
  toAccount: Account
  fromAccountID: number;
  fromAccount: Account
  order: Order
  orderID: number
  orderLineItemID: number
  orderLineItem: OrderLineItem
  childTxs: Transaction[]
  parentTx: Transaction

  constructor(input: any) {
    if (input == null) return

    input.createdByUser = new User(input.createdByUser);

    input.fees = Number(input.fees);
    input.amount = Number(input.amount);
    input.net = Number(input.net);

    input.toAccount = new Account(input.toAccount);
    input.fromAccount = new Account(input.fromAccount);
    input.processedByUser = new User(input.processedByUser)

    input.order = new Order(input.order);
    input.orderLineItem = new OrderLineItem(input.orderLineItem);
    input.childTxs = (input.childTxs || []).map(tx => new Transaction(tx))
    input.parentTx = new Transaction(input.parentTx)

    return Object.assign(this, input);
  }

  get statusColor(): string {
    switch (this.status) {
      case 'unpaid':
        return 'warning'
      case 'processing':
        return 'warning'
      case 'canceled':
        return 'error'
      case 'reverted':
        return 'error'
      case 'paid':
        return 'success'
      case 'refunded':
        return 'error'
      case 'partially refunded':
        return 'error'
    }
  }

  get actualAmount(): number {
    return this.status == "canceled" ? 0 : this.amount - this.childTxs.reduce((acc, tx) => acc += tx.amount, 0)
  }
}

export class StripeAccount {
  isSetupCompleted: boolean
  payouts_enabled: boolean
  dashboardLink: string
  onboardingLink: string
  balance: StripeBalance
  business_name: string
  email: string
  type: string
  
  constructor(input: any) {
    if (input == null) return

    input.balance = new StripeBalance(input.balance)
    return Object.assign(this, input);
  }
}

export class StripeBalance {
  available: Balance;
  pending: Balance;

  constructor(input: any) {
    if (input == null) return

    input.available = input.available ? new Balance(input.available) : null
    input.pending = input.pending ? new Balance(input.pending) : null
    //input.reservedBalance = input.reservedBalance ? new Balance(input.reservedBalance) : null
  return Object.assign(this, input);
  }
}

export class Balance {
  amount: number;
  currency: string;
  constructor(input: any) {
    return Object.assign(this, input);
  }
}

export class RevolutAccount {
  name: string
  defaultCurrency: string
  accounts: RevolutBalance[]

  constructor(input: any) {
    if (input == null) return

    input.accounts = (input.accounts || []).map(tx => new RevolutBalance(tx))

    return Object.assign(this, input);
  }

  get defaultAccount(): RevolutBalance {
    //hardcoded accountId selected for edit ldn. Repace with proper logic once another client uses revolut
    const editLDNProdDefaultAccount = this.accounts.find(acc => acc.id == "11ff7118-f9bd-4d09-9d4d-932414fd35f7")
    if (editLDNProdDefaultAccount) return editLDNProdDefaultAccount

    const currencyDefaultAccount = this.accounts.find(acc => acc.currency.toLowerCase() == this.defaultCurrency.toLowerCase())
    if (currencyDefaultAccount) return currencyDefaultAccount
    
    return this.accounts.find(acc => acc.public)[0]
  }
}

export class RevolutBalance {
  balance: number
  created_at: string
  currency: string
  id: string
  name: string
  public: boolean
  state: string
  updated_at: string

    constructor(input: any) {
      return Object.assign(this, input);
    }
}
