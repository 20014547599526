import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../core/user.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  public status: string = 'pending'; // pending, rejected

  constructor(
    private _route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.status = this._route.snapshot.paramMap.get('status') || 'pending'
  }

}
