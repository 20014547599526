import { ElementRef, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as introJs from 'intro.js/intro.js';
import { Subject } from 'rxjs';
import { ModalService } from '../shared/modal/modal.service';
import { AnalyticsService } from './analytics.service';

import { filter, take } from 'rxjs/operators';
import {EventActionEnum} from "../shared/enums/event-action.enum";
import {ApiService} from "./api.service";
import {SaleChannel} from "../shared/models/SaleChannel";
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class ToursService {
  introJS = null;
  private _activeTour: string | null;
  private _activePage: string | null; //not in use
  private _currentStep: number = 0;
  private tourParams = {
    showBullets: false,
    disableInteraction: false,
    exitOnOverlayClick: false,
    overlayOpacity: "0.8",
    steps: []
  }

  public tourHighlightClickObs = new Subject<any>()
  constructor(
      private _route: ActivatedRoute,
      private router: Router,
      private _api: ApiService,
      private _modalCtrl: ModalService,
      private _analytics: AnalyticsService,
      public user: UserService,
  ) { }

  startTour(tourName: string, params) {
    this._currentStep = 0
    this._activeTour = tourName
    switch (tourName) {
      case 'sell-item':
      case 'create-product':
        this._startTour(tourName, params)
        break;
      case 'record-sales':
        this._startTour(tourName, params)
        break;
      case 'product-template':
        this._startTour(tourName, params)
        break;
    }
  }


  _startTour(tourName: string, params) {
    /**
     * This function defines the tour steps and the behaviour of each step
     *
     * {
     *  page: page where the tour is started or resumed
     *  overwriteStep: step where the tour is started or resumed
     * }
     */
    this._activePage = params.page
    //steps builder
    switch (tourName) {
      case 'sell-item':
        switch (params.page) {
          case 'inventory-view':
            this.introJS = introJs(); // this is necessary to allow click functionality.. ionic bs took 3 hrs to figure out
            this.tourParams.steps = []
            this.tourParams.steps.push({
              id: 'sell-items',
              element: "#add-inventory",
              title: 'Step 1 of 6',
              intro: 'Click here to start selling your items',
              showButtons: false,
            })
            break;
          case 'product-search':
            this.introJS = introJs(); // this is necessary to allow click functionality.. ionic bs took 3 hrs to figure out
            this.tourParams.steps = []
            this.tourParams.steps.push({
              id: 'your-products',
              element: "#private",
              title: 'Step 2 of 6',
              intro: 'Here you find your products',
            })
            this.tourParams.steps.push({
              id: 'click-consignment',
              element: "#consignment",
              title: 'Step 3 of 6',
              intro: 'Here you find products to consign',
              nextButtonText: 'Search'
            })
            break;
          case 'product-search-retailer':
            this.introJS = introJs(); // this is necessary to allow click functionality.. ionic bs took 3 hrs to figure out
            this.tourParams.steps = []
            this.tourParams.steps.push({
              id: 'your-products',
              element: "fliproom-searchbar[test-id='fliproom-list-search']",
              title: 'Step 3 of 6',
              intro: 'Here you find products',
              nextButtonText: 'Search'
            })
            break;
          case 'inventory-form':
            this.introJS = introJs(); // this is necessary to allow click functionality.. ionic bs took 3 hrs to figure out
            this.tourParams.steps = []
            this.tourParams.steps.push({
              id: 'insert-quantity',
              element: "[tourId='add-quantity'] > div > div",
              title: 'Step 4 of 6',
              intro: 'Click here to insert the quantity to sell',
            })
            this.tourParams.steps.push({
              id: 'insert-payout',
              element: "[tourId='add-payout'] > div > div",
              title: 'Step 5 of 6',
              intro: 'Click here to insert your payout'
            })
            this.tourParams.steps.push({
              id: 'save',
              element: 'button[test-id="save"]',
              title: 'Step 6 of 6',
              intro: 'Click here to create the items to sell',
              showButtons: false,
              completed: true
            })
            break;
        }
        break;
      case 'create-product':
        this.introJS = introJs();
        this.tourParams.steps = []
        switch (params.page) {
          case 'products':
            this.tourParams.steps.push({
              id: 'create-products',
              element: "#create-product",
              title: 'Step 1 of 7',
              intro: 'Click here to start creating product',
              showButtons: false,
            })
            break;
          case 'products-mobile':
            this.tourParams.steps.push({
              id: 'create-products-mobile',
              element: '[tourId="create-product-mobile"]',
              title: 'Step Start',
              intro: 'Click here to start creating product',
              showButtons: false,
            })
            break;
          case 'products-mobile-view':
            this.tourParams.steps.push({
              id: 'select-createBtn-mobile',
              element: 'button#add-blank-product',
              title: 'Step 1 of 7',
              intro: 'Click "Add Blank Product" to start creating product',
              nextButtonText: 'Got it'
            })
            break;
          case 'products-form':
            this.tourParams.steps.push({
              id: 'add-image',
              element: '[tourId="add-image-upload"] > div',
              title: 'Step 2 of 7',
              intro: 'Click here to add image',
            })
            this.tourParams.steps.push({
              id: 'insert-title',
              element: "[tourId='title-content'] > div > div",
              title: 'Step 3 of 7',
              intro: 'Click here to insert the title',
            })
            this.tourParams.steps.push({
              id: 'insert-category',
              element: "[tourId='category-content'] > div > div",
              title: 'Step 4 of 7',
              intro: 'Click here to insert the new category',
              showButtons: false,
            })
            break;
          case 'add-variant-case':
            this.tourParams.steps.push({
              id: 'add-variant',
              element: "[tourId='add-variant-tour']",
              title: 'Step 5 of 7',
              intro: 'Click here to add the variant',
            })
            this.tourParams.steps.push({
              id: 'change-variant',
              element: "[tourId='variant-content-field']",
              title: 'Step 6 of 7',
              intro: 'Input here to change the variant',
            })
            this.tourParams.steps.push({
              id: 'create-product-submit',
              element: "[tourId='create-product-save']",
              title: 'Step 7 of 7',
              intro: 'Click on here to save your changes',
              showButtons: false,
            })
            break;
        }
        break;
      case 'record-sales':
        this.introJS = introJs();
        this.tourParams.steps = [];
        switch (params.page) {
          case 'sale-channel':
            this.tourParams.steps.push({
              id: 'sale-channel-select',
              element: ".action-sheet-group",
              title: 'Step start',
              intro: 'Click the store where you want to make the sale',
              nextButtonText: 'Got it'
            })
            break;
          case 'search-product':
            if (this.user.iam.service.warehousing) {
              this.tourParams.steps.push({
                id: 'search-product-scan',
                element: "[tourId='scan-items']",
                title: 'Search product Step',
                intro: 'Click here to scan using the barcode',
              })
            }
            this.tourParams.steps.push({
              id: 'search-product-search',
              element: "[tourId='search-items']",
              title: 'Search product Step',
              intro: 'Click here to search for a product',
              showButtons: false,
            })
            break;
          case 'move-basket-variant-mobile':
            this.tourParams.steps.push({
              id: 'move-to-stock-mobile',
              element: '[tourId="select-variant-mobile"] > div > div',
              title: 'Add to basket Step',
              intro: 'Click here to add items to the cart',
              showButtons: false,
            })
            break;
          case 'move-basket-variant':
            this.tourParams.steps.push({
              id: 'move-to-stock',
              element: "#variants-list",
              title: 'Add to basket Step',
              intro: 'Click here to add items to the cart',
              showButtons: false,
            })
            break;
          case 'move-basket':
            this.tourParams.steps.push({
              id: 'move-to-basket',
              element: "#items-list",
              title: 'Add to basket Step',
              intro: 'Click here to add items to the cart',
              showButtons: false,
            })
            break;
          case 'move-basket-mobile':
            this.tourParams.steps.push({
              id: 'move-to-basket-mobile',
              element: "[tourId='item-card-mobile']",
              title: 'Add to basket Step',
              intro: 'Click here to add items to the cart',
              showButtons: false,
            })
            break;
          case 'move-basket-sourceItem-mobile':
            this.tourParams.steps.push({
              id: 'move-to-basket-sourceItem-mobile',
              element: "[test-id='sourcing-btn']",
              title: 'Add to basket Step',
              intro: 'Click here to add items to the cart',
              showButtons: false,
            })
            break;
          case 'payment-method':
            this.tourParams.steps.push({
              id: 'payment-method-id',
              element: ".payment-methods",
              title: 'Payment method Step',
              intro: 'click here to utilize your preferred payment method',
              nextButtonText: 'Got it'
            })
            break;
          case 'paid':
            this.tourParams.steps.push({
              id: 'paid-submit',
              element: "[tourId='pay-btn']",
              title: 'Create order Step',
              intro: 'Click here to create the order on the system',
              showButtons: false,
            })
            break;
          case 'paid-mobile-first':
            this.tourParams.steps.push({
              id: 'paid-submit-first',
              element: "[tourId='complete-order']",
              title: 'Create order Step',
              intro: 'Click here to create the order on the system',
              showButtons: false,
            })
            break;
          case 'payment-method-mobile':
            this.tourParams.steps.push({
              id: 'payment-method-mobile-id',
              element: ".payment-methods",
              title: 'Payment method Step',
              intro: 'click here to utilize your preferred payment method',
              nextButtonText: 'Got it'
            })
            break;
          case 'paid-mobile-end':
            this.tourParams.steps.push({
              id: 'paid-submit-end',
              element: "[tourId='pay-btn-mobile']",
              title: 'Create order Step',
              intro: 'Click here to create the order on the system',
              showButtons: false,
            })
            break;
        }
        break;
      case 'product-template':
        this.introJS = introJs();
        this.tourParams.steps = [];
        switch (params.page) {
          case 'product-template-search-mobile':
            this.tourParams.steps.push({
              id: 'search-product-template',
              element: "fliproom-searchbar[tourId='fliproom-list-search-tour'] > mat-form-field > div > div",
              title: 'Step 1 of 8',
              intro: 'Search and choose products here.',
              nextButtonText: 'Got it'
            })
            break;
          case 'product-type':
            this.tourParams.steps.push({
              id: 'product-type-id',
              element: "button#customize-product",
              title: 'Step 2 of 8',
              intro: 'Click Customize Product to start creating product',
              showButtons: false,
            })
            break;
          case 'products-template-form':
            this.tourParams.steps.push({
              id: 'change-images',
              element: '[tourId="product-image"]',
              title: 'Step 3 of 8',
              intro: 'You can add product image or information'
            })
            this.tourParams.steps.push({
              id: 'insert-title',
              element: "[tourId='title-content'] > div > div",
              title: 'Step 4 of 8',
              intro: 'You can change product information',
            })
            this.tourParams.steps.push({
              id: 'variant-name',
              element: '[tourId="variant-content"]',
              title: 'Step 5 of 8',
              intro: "Choose and modify your variant's name"
            })
            this.tourParams.steps.push({
              id: 'synced-variant',
              element: '[tourId="synced-variant"]',
              title: 'Step 6 of 8',
              intro: 'Modify the mapped market variant associated to your internal one'
            })
            this.tourParams.steps.push({
              id: 'gtin-code',
              element: '[tourId="gtin-code"]',
              title: 'Step 7 of 8',
              intro: 'Click here to modify the GTIN codes',
            })
            this.tourParams.steps.push({
              id: 'final-save',
              element: '[tourId="create-product-save"]',
              title: 'Step 8 of 8',
              intro: 'Click here to save the changes and create the product',
              showButtons: false,
            })
            break;
        }
        break;
    }

    this.introJS.setOptions(this.tourParams).start();

    if (params.overwriteStep) {
      this.introJS.goToStepNumber(params.overwriteStep + 1);
    }

    //first step card rendering
    this.onStepCardRendering()

    //when step changes, we need to know at what step we are in so that we can apply the step config defined above
    const ctxRef = this
    this.introJS.onchange(function(data) {
      const introJSEvent = this
      //keep track of the current step
      ctxRef._saveCurrentStep(introJSEvent._currentStep)
      //emit event when step changes
      ctxRef.tourHighlightClickObs.next({action: 'step-change', stepId: ctxRef.tourParams.steps[ctxRef._currentStep].id})

      //step card rendering
      ctxRef.onStepCardRendering()
    });

    //add listeners - need to wait 0.5 sec after tutorial started so that div.introjs-helperLayer (the clickable element) is rendered
    setTimeout(() => {
      //// enable click on overlay - sends event to observable and various component can subscribe to it
      document.querySelector('div.introjs-helperLayer').addEventListener('click', (event) => {
        ctxRef.tourHighlightClickObs.next({action: 'click', stepId: ctxRef.tourParams.steps[ctxRef._currentStep].id})
      })

      //subscribe to user exiting/dismissing the tour, applys only on the cross button click - overlay click has been disabled. Prompt feedback
      document.querySelector('a.introjs-skipbutton').addEventListener('click', (event) => {
        // track event for analytics
        ctxRef._analytics.trackEvent('tour_dismissed', {name: ctxRef._activeTour, step: ctxRef.tourParams.steps[ctxRef._currentStep].id})

        // prompt user with option to resume tour
        this._modalCtrl.info(`Tour Dismissed`, 'resume').pipe(
            take(1),// take 1 so that when resume - we don't subscribe to dismiss too
        )
            .subscribe((actionName) => actionName === 'resume' ? ctxRef.onResume() : ctxRef._activeTour = null)
      })
    }, 500)
  }

  onStepCardRendering() {
    // stepConfig.nextButtonText - customize next button text. Note: need to have a timeout otherwise updates the next of the previous element
    if (this.tourParams.steps[this._currentStep].nextButtonText) {
      setTimeout(() => {
        document.querySelector('.introjs-nextbutton').innerHTML = this.tourParams.steps[this._currentStep].nextButtonText;
      }, 300)
    }

    // stepConfig.showButtons -  show/hide action buttons - If step config has showButtons = false, hide action buttons
    if (this.tourParams.steps[this._currentStep].showButtons === false) {
      (document.querySelector('div.introjs-tooltipbuttons') as HTMLElement).style.display = 'none';
    } else if (document.querySelector('div.introjs-tooltipbuttons')) { //need to check if tooltipbuttons otherwise in some scenarios breaks
      (document.querySelector('div.introjs-tooltipbuttons') as HTMLElement).style.display = 'block';
    }
  }

  nextStep() {
    if (!this._activeTour) {
      return
    }
    const _introJs = this.introJS

    setTimeout( function() {
      _introJs.nextStep();
    },200);
  }

  _saveCurrentStep(stepIdx: number) {
    this._currentStep = stepIdx
  }

  onResume() {
    this._analytics.trackEvent('tour_resumed', {name: this._activeTour, step: this.tourParams.steps[this._currentStep].id})
    this.startTour(this._activeTour, {page: this._activePage, overwriteStep: this._currentStep})
  }

  completed() {
    this.introJS.exit(true)
    //triggered when the whole tourId has been completed
    if (this.tourParams.steps[this._currentStep].completed === true) {
      this._analytics.trackEvent('tour_finish', {name: this._activeTour})
      this.createEvent(`tour-${this._activeTour}`, EventActionEnum.Completed)
      this._modalCtrl.actionCompleted()
    }
  }

  get activeTour(): string | null {
    return this._activeTour
  }

  createEvent(resource: string, action: EventActionEnum) {
    const body = { resource, action };
    this._api.createEvent(body).subscribe((resp) => {});
  }
}
