import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

export interface ITextAreaInputComponent {
  title: string;
  submitButtonTitle?: string;
  cancelButtonTitle?: string;
  textAreaInput?: string;
  textAreaPlaceholder?: string;
  fieldLabel?: string;
}

export interface InputResponse {
  data: any
  role: string // confirm | undefined
}

@Component({
  selector: 'app-textAreaInput',
  templateUrl: './textAreaInput.component.html',
  styleUrls: ['./textAreaInput.component.css'],
})
export class TextAreaInputComponent implements OnInit {
  @Input() data: ITextAreaInputComponent;
  @ViewChild('textareaEl') textareaEl: ElementRef<HTMLTextAreaElement>;

  public textAreaInputForm = new FormGroup({
    textAreaInput: new FormControl(null)
  })
  constructor(
    private _modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    if (this.data.textAreaInput) {
      this.textAreaInputForm.patchValue({
        textAreaInput: this.data.textAreaInput
      })
    }

    setTimeout(() => {
      this.textareaEl.nativeElement.focus()
    }, 400)
  }

  onSubmit() {
    this._modalCtrl.dismiss(this.textAreaInputForm.value.textAreaInput, 'submit');
  }

  onCancel(): void {
    this._modalCtrl.dismiss(null);
  }

}
