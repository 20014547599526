import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from '../core/api.service';
import { UserService } from '../core/user.service';
import { ConsignorFormComponent } from './components/consignor-form/consignor-form.component';
import { FliproomListComponent } from '../shared/fliproom-list/fliproom-list.component';
import { ModalService } from '../shared/modal/modal.service';
import { TableWrapperComponent, TableConfiguration, DataRequest } from '../shared/table-wrapper/table-wrapper.component';
import { SaleChannel } from '../shared/models/SaleChannel';
import { Address } from '../shared/models/Address.model';

export class Consignment {
  name: string
  since: Date
  lastVisitAt: Date
  status: string
  tier: string
  fulfillmentPolicy: string
  onboardingQuestions?: Object
  shippingAddress: Address
  paymentMethods: {gateway: string, status: string, message: string}[]
  analytics: {
    salesAmount: number
    averageOrderValue: number
    pendingSales: number
    canceledSales: number
    listings: number
  }
  revolutAccount: any

  constructor(consignment: any) {
    this.name = consignment.name
    this.since = consignment.since
    this.lastVisitAt = consignment.lastVisitAt
    this.status = consignment.status
    this.tier = consignment.tier
    this.fulfillmentPolicy = consignment.fulfillmentPolicy
    this.onboardingQuestions = consignment.onboardingQuestions
    this.shippingAddress = new Address(consignment.shippingAddress)
    this.paymentMethods = consignment.paymentMethods
    this.analytics = consignment.analytics
    this.revolutAccount = consignment.revolutAccount
  }

  get statusColor(): string {
    switch (this.status) {
      case 'active':
        return 'success'
      case 'pending approval':
        return 'warning'
      case 'rejected':
        return 'error'
      case 'vacation':
        return 'warning'
      default:
        return 'primary'
    }
  }

  get tierColor() {
    switch (this.tier) {
      case 'bronze':
        return 'bronze'
      case 'silver':
        return 'silver'
      case 'gold':
        return 'gold'
      default:
        return 'error'
    }
  }
}
@Component({
  selector: 'app-consignors',
  templateUrl: './consignors.page.html',
  styleUrls: ['./consignors.page.scss'],
})
export class ConsignorsPage implements OnInit {
  @ViewChild('tableWrapper') tableWrapper: TableWrapperComponent;
  @ViewChild('fliproomList') fliproomList: FliproomListComponent;

  public environment = environment
  public buttons = [
    {label: 'invite', icon: 'share', id: 'invite'}
  ]

  public tableConfigs: TableConfiguration = new TableConfiguration({
    columnsConfig: [
      {reference: 'consignor.name',                displayedName: 'Name',         dataType: 'string'},
      {reference: 'createdAt',                displayedName: 'Consignor Since',         dataType: 'date'},
      {reference: 'fulfillment_policy',            displayedName: 'Fulfillment Policy',         dataType: 'string'},
      {reference: 'saleChannels.title',            displayedName: 'Sale Channels',         dataType: 'string'},
      {reference: 'payments',            displayedName: 'Payments',         dataType: 'string', disableFilter: true},
      {reference: 'status',              displayedName: 'Status',       dataType: 'string'},
      {reference: 'tier',                displayedName: 'Tier',         dataType: 'string'},
    ],
    tableKey: 'consignors',
    showColumnsSelection: true,
    showAdvancedFilter: true,
    rowHoverable: true,
    emptyTablePlaceholder: 'No Consignors Available',
    placeholderButtonText: 'Send Invite',
    dataSourceFnName: 'getConsignors' // pass this to allow table download
  })

  public dataRequested;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _api: ApiService,
    private _modalCtrl: ModalService,
    public user: UserService
  ) { }

  ngOnInit() {
  }

  onRefresh() {
    this.tableWrapper ? this.tableWrapper.refresh() : null
    this.fliproomList ? this.fliproomList.refresh() : null
  }

  onDataRequest(evt: DataRequest): void {
    evt.params.accountID = this.user.account.ID
    this._api.getConsignors(evt.pageIdx, evt.pageSize, evt.sort, evt.params).subscribe((resp) => {
      this.dataRequested = resp;
    });
  }

  onRowClick(consignmentRecord) {
    this._router.navigate([`/consignors/${consignmentRecord.consignor.ID}`])
  }

  getSaleChannels(saleChannels: SaleChannel[]) {
    return saleChannels.map(saleChannel => saleChannel.title).join(', ')
  }

  onButtonClick(buttonId: string) {
    if (this.user.account.isConsignor && buttonId == 'invite') {
      this._modalCtrl.info(`This function is currently unavailable for your account`)
      return
    }

    if (buttonId == 'invite') {
      const rootUrl = window.location.origin;
      let baseLink = `/signup?consignInvite=${this.user.account.name.replace(/\s/g, '-')}`;
      
      if (this.user.account.ID == 3) {
        baseLink = `${baseLink}-consignment`;
      }
      
      let fullLink = '';
      switch (environment.name) {
        case 'local':
          fullLink = `${rootUrl}${baseLink}`;
          break;
        case 'production':
          fullLink = rootUrl === 'https://app.wiredhub.io'
            ? `https://app.wiredhub.io${baseLink}`
            : `https://app.fliproom.io${baseLink}`;
          break;
        case 'staging':
          fullLink = `https://staging.fliproom.io${baseLink}`;
          break;
        default:
          break;
      }

      try {
        navigator.clipboard.writeText(fullLink);
        this._modalCtrl.clipboard({
          title: 'Invitation Link',
          buttonText: 'Copy',
          value: fullLink,
          valueLabel: 'Link'
        }, { cssClass: 'custom' }).pipe(filter(res => res)).subscribe((res) => {
          this._modalCtrl.info('Invitation Link copied to clipboard')
        });
      } catch (err) {
        console.error('Failed to copy invitation link: ', err);
      }
    }
  }

}
