import {Deserializable} from './helpers/deserializable';
import {Product, ProductVariant} from './Product.model';
import {Item} from './Item.model';
import {Account} from 'src/app/core/user.service';
import {Warehouse, WarehouseLocation} from './Warehouse.model';
import {Status} from "./Status.model";
import {SaleChannel} from './SaleChannel';


export class Integration {
    ID: number
    newProductsAvailable : number
    productsAvailable : number
    productsIncomplete : number
    productsArchived : number
    productsSelling : number
    destinationAccountID: number
    sourceAccountID: number
    destinationAccount: Account
    sourceAccount: Account
    destinationSaleChannelID: number
    sourceSaleChannelID: number
    destinationSaleChannel: SaleChannel
    sourceSaleChannel: SaleChannel
    createdAt: Date
    updatedAt: Date


    constructor(data: any) {
        if (data == null) {
            return null;
        }

        data.destinationAccount = new Account(data.destinationAccount)
        data.sourceAccount = new Account(data.sourceAccount)
        data.destinationSaleChannel = new SaleChannel(data.destinationSaleChannel)
        data.sourceSaleChannel = new SaleChannel(data.sourceSaleChannel)


        return Object.assign(this, data)
    }


}

export class IntegrationLineItem {
    ID: number
    sourceProductID: number
    destinationProductID: number
    sourceProduct: Product
    destinationProduct: Product
    pricingRuleID: number
    pricingRule: PricingRule
    status: string

    constructor(data: any) {
        if (data == null) return


        data.sourceProduct = new Product(data.sourceProduct)
        data.destinationProduct = new Product(data.destinationProduct)
        data.pricingRule = new PricingRule(data.pricingRule)
        return Object.assign(this, data)
    }
}


//TODO: move to a separate file
export class PricingRule {
    ID: number
    accountID: number
    account: Account
    name: string
    value: string | number
    type: string
    createdAt: Date
    updatedAt: Date
    target: string
    apply: string

    constructor(pricingRule: any) {
        if (pricingRule == null) return
        pricingRule.account = new Account(pricingRule.account)
        return Object.assign(this, pricingRule)
    }

}

export interface IntegrationCreateBody {
    destinationAccountID:             number
    sourceAccountID:                  number
    sourceSaleChannelID:         number
    destinationSaleChannelID:    number
}

export interface IntegrationLineItemUpdateBody {
    action: string,
    productUpdates?: any //TODO: cast to Product OBJ??
    pricingRuleID?: number
}
