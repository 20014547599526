import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import {ModalService} from "../modal.service";
import {mergeMap} from "rxjs/operators";
import {ApiService} from "../../../core/api.service";
import {EventActionEnum} from "../../enums/event-action.enum";

export interface IHelpPanelComponent {
  serviceName: string;
}

export interface IQuery {
  query: string;
}

@Component({
  selector: 'app-help-panel',
  templateUrl: './help-panel.component.html',
  styleUrls: ['./help-panel.component.scss']
})
export class HelpPanelComponent implements OnInit {
  @Input() data: IHelpPanelComponent;

  private resourcesDatabase = {
    'products': [
      {id: 'import-product', title: 'Import Product', mobileVideoUrl: 'https://www.youtube.com/embed/6u52sY2isJY', webVideoUrl: 'https://www.youtube.com/embed/_FfvgTVKCxM'}
    ],
    'inventory': [
      {id: 'add-inventory', title: 'Add Inventory', mobileVideoUrl: 'https://www.youtube.com/embed/NFCVShOtHCI', webVideoUrl: 'https://www.youtube.com/embed/XKKjb9XuyDQ'}
    ],
    'orders': [
      {id: 'create-order', title: 'Record Sale', mobileVideoUrl: 'https://www.youtube.com/embed/uyswedS0cik', webVideoUrl: 'https://www.youtube.com/embed/pw7ILUvnOyI'}
    ]
  }

  public resources = []
  public envi = environment
  constructor(
    private _modalCtrl: ModalController,
    private modalCtrl: ModalService,
    private _sanitizer: DomSanitizer,
    private _api: ApiService
  ) { }

  ngOnInit(): void {

    for (var res of this.resourcesDatabase[this.data.serviceName]) {
      // build resources to display from db of resources
      this.resources.push({id: res.id, title: res.title, url: this._sanitizer.bypassSecurityTrustResourceUrl(environment.platform == 'web' ? res.webVideoUrl : res.mobileVideoUrl)})
    }
  }

  onAskSubmit() {
    this._modalCtrl.dismiss();
    const modalConfig = {}
    if (this.envi.screenType == 'mobile') {
      modalConfig['cssClass'] = 'full-screen'
    }
    this.modalCtrl.supportModal({origin:`${this.data.serviceName}-help-panel`}, modalConfig).subscribe((res: string) => {
      this.modalCtrl.success('Sent successfully!. We will get back to you soon.')
    })
  }

  createEvent(resource: string, action: EventActionEnum, metadata:IQuery) {
    const body = { resource, action, metadata };
    this._api.createEvent(body).subscribe((resp) => {});
  }

  onSubmit(isConfirmed) {
    this._modalCtrl.dismiss(isConfirmed, 'submit');
  }

}
