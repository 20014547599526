import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import { ModalService } from '../modal.service';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/core/analytics.service';
import {Subscription} from "rxjs";
import { ApiService } from 'src/app/core/api.service';

export interface IFeedbackInterfaceComponent {
  title: string,
  subTitle?: string,
  id: string
  reasons?: string[],
  optionCards?: OptionCard[],
  mode?: string // 'feedback' || 'poll'  || 'feedback-bulk' || 'option-cards'
  cssClass?: string
  submitOnOptionClick?: boolean, // 'option-cards'
  hideSubmit?: boolean ,
  showInterestDismissal?: boolean
  ionContentAlt?: boolean

}

export interface OptionCard {
  title: string,
  id: string,
  description: string,
  list: string[],
  suffix: string,
}


@Component({
  selector: 'app-feedback-interface',
  templateUrl: './feedback-interface.component.html',
  styleUrls: ['./feedback-interface.component.scss'],
})
export class FeedbackInterfaceComponent implements OnInit {

  private onCloseSubscription: Subscription;

  @Input() data: IFeedbackInterfaceComponent;
  public rating: number;
  public disabled: boolean;
  public mode: string = ''
  Math: any;
  parseFloat: any;
  iconsArray: number[] = [];
  @Input()
  readonly: string = 'false';
  @Input() maxRating: number = 5;
  @Input() fontSize: string = '36px';

  public displayReasons: boolean = false; // set to true once the user selectes a rating. Used to display the reasons
  public feedbackForm = new FormGroup({
    feedbackId: new FormControl(null),
    rating: new FormControl(null),
    option: new FormControl(null),
    extraFeedback: new FormControl(null), // TODO: integrate into reasons
    reasons: new FormArray([],c => {
      const atleastOneChecked = (c as FormArray).controls.find(x => x.value === true);
      return atleastOneChecked ? {} : { noOptionSelected: true };
    })
  })



  constructor(
    private _modalService: ModalService,
    private modalController: ModalController,
    private analytics: AnalyticsService,
    private _modalCtrl: ModalController,
    private _api: ApiService
  ) {
    this.Math = Math;
    this.parseFloat = parseFloat;
  }

  ngOnInit(): void {
    for (let i = 0; i < this.maxRating; i++) {
      this.iconsArray.push(i);
    }


    this.feedbackForm.patchValue({
      feedbackId: this.data.id
    })


    this.mode = this.data.mode
    //load topics

    if (this.mode === "poll") {
      this.data.reasons.map((reason: string) => (this.feedbackForm.get('reasons') as FormArray).push(new FormControl(false)))
      this.displayReasons = true
    }
    else if (this.mode === "option-cards") {
      this.feedbackForm.get('reasons').disable()
      //make option field required
      this.feedbackForm.get('option').setValidators(Validators.required)
    }

    //subscribe to when modal is closed
    this.onCloseSubscription = this._modalService.onClose.subscribe(() => {
      const data = {
        feedbackId: this.feedbackForm.get('feedbackId').value,
        rating: this.feedbackForm.get('rating').value,
      }

      if(this.data.reasons){
        // need to send reasons as its own keys to GA because GA doesnt support arrays
        this.data.reasons.filter((reason, idx) => this.feedbackForm.get('reasons').value[idx]).map((reason, idx) => {
          data[`reason${idx + 1}`] = reason
        })
      }


      if (this.feedbackForm.get('reasons').enabled &&( data.rating !== null || !this.feedbackForm.get('reasons').errors.noOptionSelected)) {
        let i = 0;
        let reasonValue = []
        for (let reason of this.feedbackForm.get('reasons').value) {
          if (reason){
            if(this.data.reasons[i] == 'Other'){
              reasonValue.push(this.feedbackForm.get('extraFeedback').value)
            } else {
              reasonValue.push(this.data.reasons[i])
            }
            // this.analytics.trackEvent('feedback_submitted', {
            //   feedbackId: this.data.id,
            //   reason1: reasonValue
            // })
          }
          i ++
        }
        this._api.createEvent({
          resource: this.data.id,
          action: 'feedback_submitted',
          metadata: {
            reasons: reasonValue
          }
        }).subscribe((resp) => {});
        this._modalService.success('Your opinion matters to us, thank you!')
      }

      else if( this.feedbackForm.get('option').value){
        this._api.createEvent({
          resource: this.data.id,
          action: 'feedback_submitted',
          metadata: {
            option: this.feedbackForm.get('option').value.id
          }
        }).subscribe((resp) => {});
        this._modalService.success('Request for beta access received. We will get back to you soon')
      }
      else {
        this._api.createEvent({
          resource: this.data.id,
          action: 'feedback_dismissed'
        }).subscribe((resp) => {});
      }
      this.onCloseSubscription.unsubscribe();
    })

  }

  onCancel() {
    this._modalCtrl.dismiss()
  }
  // Assuming feedbackForm is of type FormGroup
  get reasonsFormArray() {
    return this.feedbackForm.get('reasons') as FormArray;
  }

  onOptionClick(index) {
    this.feedbackForm.patchValue({
      option: this.data.optionCards[index]
    })
    if(this.data.submitOnOptionClick){
      this.onSubmit()
    }
  }

  onNotInterested() {
    this.analytics.trackEvent('feedback_dismissed', {
      feedbackId:  this.data.id,
    })
    this.modalController.dismiss('not-interested', 'submit')
  }


  onSubmit() {

    this.feedbackForm.markAsTouched()
    if (this.mode == "poll" && (this.feedbackForm.get('reasons').errors.noOptionSelected || (this.data.reasons.includes('Other') && this.feedbackForm.get('reasons').value.slice(-1)[0]  && (this.feedbackForm.get('extraFeedback').value == null))) ) {
      console.log(this.feedbackForm.get('reasons').value)
      console.log(this.feedbackForm.get('reasons').value.slice(-1) )
      return
    }

    if(this.mode == "option-cards" && this.feedbackForm.invalid){
      return
    }
    this.modalController.dismiss('submitted', 'submit')
  }

  protected readonly Option = Option;
}
