import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {ApiService} from "../../../core/api.service";
import {EventActionEnum} from "../../enums/event-action.enum";
import {IQuery} from "../help-panel/help-panel.component";
import { UserService } from 'src/app/core/user.service';
import html2canvas from 'html2canvas';
import { ModalService } from '../modal.service';
import { forkJoin } from 'rxjs';

export interface ISupportModalComponent {
  origin?: string
}

interface Topic {
  id: string;
  label: string;
}

@Component({
  selector: 'app-supportModal',
  templateUrl: './supportModal.component.html',
  styleUrls: ['./supportModal.component.scss'],
})
export class SupportModalComponent implements OnInit {
  @Input() data: ISupportModalComponent;
  @ViewChild('textareaEl') textareaEl: ElementRef<HTMLTextAreaElement>;
  @ViewChild('inputEl') inputEl: ElementRef<HTMLInputElement>;

  public supportModalForm = new FormGroup({
    email: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    includeScreenshot: new FormControl(true),
    screenshotDataURL: new FormControl(null),
  })

  public isLoadingAction = false

  constructor(
    private _modalCtrl: ModalController,
    private _api: ApiService,
    private _modalService: ModalService,
    private user: UserService
  ) { }

  ngOnInit() {
    this.supportModalForm.patchValue({ email: this.user.email});
    this.supportModalForm.get('email').disable();
    this._api.createEvent({
      resource: 'support-request',
      action: EventActionEnum.Viewed,
      metadata: { origin: this.data.origin}
    }).subscribe()

    setTimeout(() => {
      const screenshotTarget = document.body;
      html2canvas(screenshotTarget).then(canvas => {
        // to image as png use below line
        // const base64image = canvas.toDataURL("image/png");
        // show the image in window use below line
        // window.location.href = base64image;
      
        // screenshot appended to the body as canvas
        //document.body.appendChild(canvas);  
        this.supportModalForm.patchValue({ screenshotDataURL: canvas.toDataURL() });
      });
    }, 500)
  }

  onSubmit() {
    // mark all fields as touched
    this.supportModalForm.markAllAsTouched();
    if (this.supportModalForm.valid) {
      this.isLoadingAction = true

      forkJoin({
        event: this._api.createEvent({
          resource: 'support-request',
          action: EventActionEnum.Submitted,
          metadata: { 
            origin: this.data.origin,
            description: this.supportModalForm.get('description').value,
            includesScreenshot: this.supportModalForm.get('includeScreenshot').value
          }
        }),
        supportRequest: this._api.createSupportRequest({
          email: this.supportModalForm.get('email').value,
          description: this.supportModalForm.get('description').value,
          screenshotDataURL: this.supportModalForm.get('screenshotDataURL').value
        })
      }).subscribe((resp) => {
        this._modalService.success(`Thank you! Our support team will review your request and get back to you shortly at ${this.supportModalForm.get('email').value}.`)
        this._modalCtrl.dismiss(this.supportModalForm.value, 'submit');
      }).add(() => {
        this.isLoadingAction = false
      
      })

    } 
  }

  onCancel(): void {
    this._modalCtrl.dismiss(null);
  }

  get SupportModalFormData() {
    return this.supportModalForm.getRawValue()
  }

}
